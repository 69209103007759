import React, { useEffect } from "react";
import { Route, Switch, Link } from "react-router-dom";
import AccountsRouter from "traec-react/auth/router";
import NavBar from "traec-react/navBar";
import { ErrorBoundary } from "traec-react/errors";

import Traec from "traec";
import Home from "./home";
import LoginRedirect from "./login-redirect";
import UserProfile from "AppSrc/user";
import AdminPage from "AppSrc/admin";
import GeoReport from "AppSrc/report/geo";
import SBCCReport from "AppSrc/report/sbcc";
import { ReportAdminPage } from "AppSrc/admin/report";
import CompanyProjectPage from "AppSrc/tree/mainPage";
import NavBarBrand from "./brand";
import CookieConsent, { Cookies, getCookieConsentValue, resetCookieConsentValue } from "react-cookie-consent";
import { TermsAndConditions, TermsAndConditionsRedirector } from "storybook-dashboard/contracts/terms";

// Route-based code splitting
// https://reactjs.org/docs/code-splitting.html
//const Company = React.lazy(() => import(/* webpackChunkName: "company" */ './company'))
//const Project = React.lazy(() => import(/* webpackChunkName: "project" */'./project'))

export const getCreateText = () => {
  let is_sbcc = location.hostname.startsWith("sbcc") || location.hostname.includes("localhost");
  return is_sbcc ? "Create an account" : "Create a supplier account";
};

function jiraBugCollectorFieldValues() {
  //console.log("Getting field values for support form submission");
  var values = {
    //fullname: "User name",
    //email: "user@my.domain",
    recordWebInfo: "1", // field Name
    recordWebInfoConsent: ["1"], // field Id
  };
  return values;
}

window.ATL_JQ_PAGE_PROPS = {
  triggerFunction: function (showCollectorDialog) {
    jQuery("#jira-bug-collector").click(function (e) {
      e.preventDefault();
      showCollectorDialog();
    });
  },
  fieldValues: jiraBugCollectorFieldValues,
};

function SupportDropdown() {
  return (
    <React.Fragment>
      {/*
      <li className="nav-item">
        <a className="nav-link" href="/accounts/profile/">
          <Octicon name="bell" />
        </a>
      </li>
      */}
      <li className="nav-item dropdown">
        <a
          className="nav-link dropdown-toggle"
          href="#"
          id="navbarDropdown"
          role="button"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          {/*<Octicon name="question" scale={1.5} />*/}
          Help
        </a>
        <div className={`dropdown-menu dropdown-menu-right`} aria-labelledby="navbarDropdown">
          <a
            href="https://procedural-public-media.s3.eu-central-1.amazonaws.com/track/user_defined/Suppliers+-+User+guide+Carbon+Calculator+2023.docx"
            download
            target="_blank"
            className="dropdown-item"
          >
            Supplier's User Guide
          </a>
          <a
            href="https://procedural-public-media.s3.eu-central-1.amazonaws.com/track/user_defined/Partners+-+User+guide+Carbon+Calculator+2023.docx"
            download
            target="_blank"
            className="dropdown-item"
          >
            Partner's User Guide
          </a>
          <a
            href="mailto:info@sustainabilitytool.com?subject=Carbon Calculator - Message support&body=Please complete the details below and send the email. The Sustainability Tool team will then be glad to support your query. %0D%0A %0D%0A 
            1. Full name: %0D%0A 
            2. Company name: %0D%0A 
            3. The URL (link) of the page you require support with: %0D%0A 
            4. A screenshot of the page (if possible): %0D%0A 
            5. A short description of your issue or query: %0D%0A %0D%0A
            We aim to respond to all our queries within 24 hours."
            className="dropdown-item"
            target="_blank"
          >
            Message Support
          </a>
          <Link to="/terms" className="dropdown-item">
            Terms of Service & Privacy Policy
          </Link>
        </div>
      </li>
    </React.Fragment>
  );
}

function MainSwitch(props) {
  //console.log("REDIRECT AT MAINSWITCH", props);
  return (
    <Switch>
      {/* there will only ever be one child in the Switch */}
      <Route exact path="/" component={Home} />

      {/* Terms and conditions page */}
      <Route exact path="/terms" component={TermsAndConditions} />

      {/* Registration and email activation */}
      <Route
        path="/accounts"
        render={(routeProps) => (
          <AccountsRouter
            {...routeProps}
            UserProfile={UserProfile}
            createText={getCreateText()}
            metaFieldProps={[{ name: "company_name", placeholder: "Company Name" }]}
            azureConfig={getAzureConfig()}
          />
        )}
      />

      {/* Route to a Company Dashboard */}
      <Route path="/company/:_companyId" component={CompanyProjectPage} />

      {/* Route to a Project or WorkPackage Dashboard */}
      <Route path="/project/:_projectId/wpack/:_refId" component={CompanyProjectPage} />
      <Route path="/project/:_projectId" component={CompanyProjectPage} />

      {/* Router to the admin path */}
      <Route path="/tenant/admin/" component={AdminPage} />
      <Route path="/tenant/reports/" component={ReportAdminPage} />

      {/* Pages for reporting */}
      <Route exact path={`/report/geo`} component={GeoReport} />
      <Route exact path={`/report/sbcc/:_type/:_typeId`} component={SBCCReport} />

      {/* Default render homepage if no path matched */}
      <Route exact path={`/home`} component={Home} />
      <Route component={Home} />
    </Switch>
  );
}

const getSSOHost = () => {
  let thisHost = window.location.host;
  let host = thisHost;
  if (!host.includes("localhost")) {
    host = thisHost.includes("test.sustainabilitytool.com")
      ? "uk.test.sustainabilitytool.com"
      : "uk.sustainabilitytool.com";
    host = `https://${host}`;
  } else {
    host = `http://${host}`;
  }
  return host;
};

export const getAzureConfig = () => {
  return {
    appId: "2940b826-09d5-43c8-bc1e-4c6295255a0c",
    authority: "https://login.microsoftonline.com/common",
    ssoRedirectPage: `${getSSOHost()}/accounts/login/sso/azure`,
    redirectUri: getSSOHost(),
    scopes: ["user.read"],
  };
};

export function AppRouter(props) {
  //console.log("REDIRECT RENERING AppRouter");
  //console.log("cookie consent >", getCookieConsentValue());

  useEffect(() => {
    Traec.fetchRequiredFor({
      props,
      requiredFetches: [new Traec.Fetch("version", "read"), new Traec.Fetch("tenant_meta", "read")],
    });
  });

  return (
    <ErrorBoundary title="Error this page">
      <LoginRedirect>
        <TermsAndConditionsRedirector />

        <NavBar
          brand={<NavBarBrand />}
          preUserItems={<SupportDropdown />}
          include_myprofile={false}
          includeUser={true}
          getUserItemLabel={(user) => user.get("email")}
          userLabel={"My account"}
          createText={getCreateText()}
          azureConfig={getAzureConfig()}
        />
        <CookieConsent
          contentStyle={{ margin: "10px 0px 10px 10px" }}
          disableStyles={true}
          style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}
          buttonClasses="btn btn-primary"
          containerClasses="alert alert-warning col-lg-12"
          buttonText="OK"
          expires={1}
        >
          <p className="m-0">
            We are making some important updates which may impact your experience using the system at this time. Do not
            hesitate to{" "}
            <a
              className="cookie-email"
              style={{ color: "black" }}
              href="mailto:info@actionsustainability.com?subject=Impacted by updates&body=Please describe your issue and when you experienced it."
              target="_blank"
            >
              contact us
            </a>{" "}
            so we can ensure there is no disruption to your business reporting as usual. Thank you for your continued
            support and patience.
          </p>
        </CookieConsent>
        <MainSwitch />
      </LoginRedirect>
    </ErrorBoundary>
  );
}
